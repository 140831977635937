.supplemental,
.control_approval,
.legal_decision,
.legal_recommendation,
.control_recommendation{
    @include section;
}

.control_approval{
    #pnlExtremelyHighRiskHazards,
    #pnlHighRiskHazards,
    #pnlMediumRiskHazards,
    #pnlLowRiskHazards
    {
        display: none !important;
    }    
    #pnlQuickAction textarea{
        margin-bottom: 30px;
    }
}

.control_recommendation{
    #MainContent_grdQuickHazards_grdHazards tr{
        th:last-child,
        td:last-child{
            display: none;
        }
    }    
}


.dashboard{
    #summary .table tr td:first-child{
        font-weight: bold;
    }
    .strong_heading{
        padding-bottom: 20px;
        display: block;
    }
}
.back-to-previous{
    display: block;
    padding: 20px 0;
    i{
        padding-right: 10px;
    }
}
#pnlControlApproval,
.recommendControls{
    .h4,
    .h3{
        font-size: 24px;
        margin: 60px 0 20px;
    }
    .h5{
        font-size: 18px;
        margin: 60px 0 20px;
    }
    .table{
        tr th{
            &:nth-child(1){
                width: 30%;
            }
            &:nth-child(2){
                width: 40%;                
            }
            &:nth-child(3){
                width: 15%;
            }
            &:nth-child(4){
                width: 15%;
            }
        }
    }
}
#grdRiskCategory,
#pnlRiskByCategory,
#pnlHazardByCategory{
    .table tr th:last-child{
        width: 10%;
        text-align: center;
    }
    .table tr td:last-child{
        text-align: center;
    }
}
#MainContent_grdQuickControls_grdPreControls,
#MainContent_grdQuickControls_grdPostControls{
    &.table{
         tr th:last-child{
            width: 15%;
            text-align: right;
        }
        tr td:last-child{
            text-align: right;
        }
        tr th:nth-child(2){
            width: 10%;
            text-align: center;
        }
        tr td:nth-child(2){
            text-align: center;
        }
    }
}
#MainContent_grdQuickControls_grdPreControls{
    &.table{
         tr th:nth-child(2){
            width: 10%;
            text-align: center;
        }
        tr td:nth-child(2){
            text-align: center;
        }
    }
}
.option-hide.hide{
    display: none !important;
}

.request-by-status-fieldgroup{
    margin: 20px 0;
    span{
        display: block;
    }

    .no-radius-left{
        background-color: #fff;
        border: 1px solid rgb(169, 169, 169);
        margin-left: 10px;
    }
    select{
//        display: block; 
        height: 33px;
    }

}

.control_recommendation{
    .js-toggle label{
        display: none !important;
    }
}