// Transitions
$default-transition-duration: 400ms;
$default-transition-function: ease;
$default-transition-delay: false;

$animation__ease--in: cubic-bezier(0.250, 0.460, 0.450, 0.940);
$animation__ease--out: cubic-bezier(0.550, 0.085, 0.680, 0.530);

// @mixins
//
// We use this to add transitions to elements
// $property - Default: all, Options: http://www.w3.org/TR/css3-transitions/#animatable-properties
// $speed - Default: 300ms
// $ease - Default:ease-out, Options: http://css-tricks.com/almanac/properties/t/transition-timing-function/
@mixin single-transition($property: all, $speed: $default-transition-duration, $ease: $default-transition-function, $delay: $default-transition-delay) {
    transition: $property $speed $ease;
}
