#pnlRiskByCategory{
    table tr{
        th{
            &:nth-child(2),
            &:nth-child(3){
                text-align: center;                                
            }
        }
        td{
            &:nth-child(1){
                vertical-align: middle;
            }
            &:nth-child(2),
            &:nth-child(3){
                text-align: center;                
            }
        }
    }
}