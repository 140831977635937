#pnlControlsChart{
    table tr{
        th{
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4){
                text-align: center;                                
            }
        }
        
        td{
            &:nth-child(1){
                vertical-align: middle;
            }
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4){
                text-align: center;                
            }
        }
    }
}

.radio.space-20{
    padding-left: 20px;
}
.radio-group{
    br{
        display: none;
    }
    .radio{
        display: block !important;
        margin-bottom: 4px;
    }
    label{
        font-weight: normal;
        padding-left: 20px;
        position: relative;
        top: 3px;
    }
}