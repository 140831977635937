@mixin section{
    .section{
        margin-bottom: 140px;
        > div {
            margin-bottom: 60px;
        }
        .table{
            label{
                font-weight: normal;
            }
        }
    
        .h3{
            margin-bottom: 10px;
            + .h4{
                margin-top: 30px;
            }
        }
        
        table{
            width: 100%;
            margin-left: 0;
        }
    }
}

.toggle-btn{
    input{
    	border: 0;
    	clip: rect(0 0 0 0);
    	height: 1px;
    	margin: -1px;
    	overflow: hidden;
    	padding: 0;
    	position: absolute;
    	width: 1px;    
    }
	label {
    	@extend .btn;
        @extend .btn-secondary;
        border: 1px solid #ccc;
        &:hover{
            background-color: #e6e6e6;
            border-color: #adadad;
        }
    }
    
    input:focus + label {
      //border-color: blue;
    }
    
    input:checked + label {
        @extend .btn-success;
    }
    
    input:focus:checked + label {
      //border-color: green;
    }
}
$small : 'max-width:767px';
$small-up : 'min-width:768px';    
$medium-up : 'min-width:992px';
$large-up : 'min-width:1200px';


    