#MainContent_txtComment{
    width: 100%;
}
.js-page{
    &.js-sec_nav .h1{
        margin: 50px 0 90px;
    }
    .h1{
        margin: 0 0 50px;
        .name{
            color: #777;
        }
    }

}

.scorecard_search{
    padding-bottom: 30px;
    .input-group-btn{
        .btn{
            padding-bottom: 7px;
        }
    }
}

.h1{
    margin: 50px 0 90px;
    .name{
        color: #777;
    }
}
.submit{
    float: right;
}
.risk{

    &:before{
        font-family: taft;
    }
    &__label{
       text-align: center; 
    }
    &--low{
        font-size: 20px;
        text-align: center;
        color: #4DC645;    
        &:before{
            position: relative;
            top: 6px;
            content: 'c';
        }
    }
    &--medium{
        font-size: 20px;
        text-align: center;
        color: #FFA906;    
        &:before{
            content: 'c';
            position: relative;
            top: 6px;
        }
    }
    &--high{
        font-size: 20px;
        text-align: center;
        color: #FF1500;    
        &:before{
            content: 'c';
            position: relative;
            top: 6px;
        }
    }
    
    &--xhigh{
        color: #4B4645;
        font-size: 30px;
        text-align: center;            
        &:before{
            content: 's';
        }
    }
}
hr{
    margin: 50px 0;
}
.nav--secondary{
    background: #fff;
    margin-top: 50px;
    .container{
        position: relative;
    }
    .sec-nav-toggle{
        display: block;
        position: absolute;
        top: 0;
        left: 0;
    }
    .nav{
        padding-top: 10px;
        height: 60px;
        padding-left: 30px;
        @media( $medium-up ){
            padding-left: 0px;
        }
        li{
            float: none;
            @media( $medium-up ){
                float: left;
            }    
        }
    }
}
.body-content{
    margin-top: 140px;
}
h2{
    background-color: #fff;
    background: #fff;
}
.h5{
    font-size: 16px;
    padding: 18px 0;
}
.sec_nav{

}

.utility_nav{
    margin-top: 30px;
    list-style: none;
    margin-bottom: 0;
    @media( $small-up ){
        margin-top: 0;
        top: 0;
        right: 15px;
        position: absolute;        
    }
    &__item{
        display: block;
        line-height: 60px;
        padding-left: 20px;
        @media ($small-up) {
            text-align: right;
            display: inline;            
        }
        + .utility_nav__item{
            display: block;
            @media ($small-up) {
                display: inline
            }
            margin-right: 0;
            padding-right: 0;
            //border-left: 1px solid #090909;
        }
        &__label{
            display: inline;
        }
        img{
            display: inline;
        }
        &--risk{
            
        }
        &--price{
            
        }
    }
}

.sec-nav-toggle{
    @include nav-toggle;
}
.space-bottom-30,
#btnCalcCost{
    margin-bottom: 30px;
}
.inline{
    display: inline;
}


