@mixin nav-toggle{
//        background-color: $color__header;
        display: block;
        float: right;
        position: absolute;
        right: 18px;
        top: 18px;
        height: 50px;
        width: 35px;
        z-index: 1;
        text-indent: -9999em;
        padding: 0;
        font-size: rem(12);
        color: #222;
        color: transparent;
        text-transform: uppercase;
        letter-spacing: 0.25px;
/*
        @include media( $medium-up ){
            text-indent: -0.75px;
            text-shadow: 0 20px $color__nav_item_text;  
        }
*/
        @media( $large-up ){
            text-indent: -9999em;
            text-shadow: none;              
        }
        span,
        &:before,
        &:after {
            cursor: pointer;
            border-radius: 2px;
            height: 2px;
            width: 100%;
            background: #222;
            position: absolute;
            display: block;
            content: '';
            @include single-transition(all, 0.4s, $animation__ease--out);
            right: 0;
        }
        span {
            top: 50%;
            margin-top: -1px;
        }
        &:before {
            top: 17px;
        }
        &:after {
            bottom: 17px;
        }
        .no-touch &:hover {
            span,
            &:before,
            &:after {
                background: #fff;
            }
        }
        &.active {
            span,
            &:hover span {
                background: transparent;
                @include single-transition(all, 0.4s, $animation__ease--in);
            }
            &:before {
                transform: rotate(-45deg) translate(-5px, 3px);
                @include single-transition(all, 0.4s, $animation__ease--in);
            }
            &:after {
                transform: rotate(45deg) translate(-6px, -6px);
                @include single-transition(all, 0.4s, $animation__ease--in);
            }
        }
        &:hover,
        &:active,
        &:focus{
            //color: red;
//            background-color: red;
            outline: none;
        }
 
    @media($large-up) {
        display: none;
    }
}